import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from '@mui/material/CardMedia';

import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@material-ui/core";
import "./App.css";
import logo from "./logo.png"
import heroImage from "./assets/jem-sahagun--kqC3rZEMBI-unsplash.jpg"
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FFD54F",
    },
    secondary: {
      main: "#f58e00",
    },
    background: {
      paper: '#fff',
    },
  },
  typography: {
    fontFamily: "Rasa",

    h1: {
      fontSize: "50px",
      fontFamily: "Exo"
    },
    button: {
      fontFamily: "Exo",
      fontWeight: "700",
    }
  },
});
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Zero Robotic
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const tiers = [
  {
    title: "Sentinel",
    subheader: "Observant Guardian Robot",
    image: "/static/jason-leung-iDQVmcPFOCI-unsplash.jpg",
    price: "19",
    description: [
      "Door and window guardian",
      "Transmit directly to your phone",
      "Automatic self charging",
      "Privancy ensured with AES",
    ],
    buttonText: "Start Guarding",
    buttonVariant: "contained",
  },
  {
    title: "Blueprint",
    subheader: "Smart Mapping Robot",
    image: "/static/r-architecture-wDDfbanbhl8-unsplash (1).jpg",
    price: "29",
    description: [
      "2D and 3D floorplan mapping",
      "Object and texture detection",
      "Expandable and customizable",
      "Design simulation capable",
    ],
    buttonText: "Start Mapping",
    buttonVariant: "contained",
  },
  {
    title: "Chef",
    subheader: "Consistent Cooking Robot",
    image: "/static/heather-ford-Ug7kk0kThLk-unsplash.jpg",
    price: "39",
    description: [
      "Meal suggestion and preparation",
      "Extensive, growing menu",
      "Ingredient freshness monitoring",
      "Self cleaning",
    ],
    buttonText: "Start Cooking",
    buttonVariant: "contained",
  },
];
const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];
function PricingContent() {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="secondary"
        elevation={1}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <img src={logo} alt="Zero" className="logo" />

          <nav>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              What is Zero?
            </Link>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Get Zero
            </Link>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Support
            </Link>
          </nav>
          <Button href="#" variant="contained" sx={{ my: 1, mx: 1.5 }}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <img src={heroImage} alt="Zero Robotic" className="heroImage" />
      <Box
        bgcolor="#586A7D"
        sx={{ p: 5 }}
      >
        <Typography
          component="h1"
          variant="h1"
          align="center"
          color="#fff"
          gutterBottom
        >
          What is Zero?
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color="#aaa"
          component="p"
          m={2}
        >
          Our mission is to conceive, design, and build ideas that make the world a better place.
        </Typography>
      </Box>
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >


        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          component="p"
        >
          Starting with Zero Robotic, we create single-focused, safe &amp; secure, robotic devices that do things that make your life better.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={tier.image}
                  alt={tier.title}
                />
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}

                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="text.secondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </React.Fragment >
  );
}
export default function Pricing() {
  return (
    <ThemeProvider theme={theme}>
      <PricingContent />
    </ThemeProvider>
  );
}